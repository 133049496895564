.beta-test-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-justify: center;
}

.privacy-policy {
    width: 70%;
    margin: 0 auto;
    font-size: 0.875em;
    /* adjust as needed */
}