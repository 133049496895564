body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --blue: #2762da;
  --cyan: #00e6e6;
  --yellow: #f6cb42;
  --red: #fb3e3e;
  --red2: #f96868;
  --purple: #704fff;
  --pink: #f85f89;
  --orange: #f09105;
  --green: #1bbc9d;
  --white: #fff;
}

.blue {
  color: var(--blue) !important;
}

.cyan {
  color: var(--cyan) !important;
}

.yellow {
  color: var(--yellow) !important;
}

.red {
  color: var(--red) !important;
}

.purple {
  color: var(--purple) !important;
}

.pink {
  color: var(--pink) !important;
}

.orange {
  color: var(--orange) !important;
}

.green {
  color: var(--green) !important;
}

.green-bg {
  color: var(--white) !important;
  background-color: var(--green) !important;
}

.orange-bg {
  color: var(--white) !important;
  background-color: var(--orange) !important;
}

.pink-bg {
  color: var(--white) !important;
  background-color: var(--pink) !important;
}

.purple-bg {
  color: var(--white) !important;
  background-color: var(--purple) !important;
}

.red-bg {
  /* color: var(--white) !important; */
  background-color: var(--red2) !important;
}

.yellow-bg {
  color: var(--white) !important;
  background-color: var(--yellow) !important;
}

.cyan-bg {
  color: var(--white) !important;
  background-color: var(--cyan) !important;
}

.blue-bg {
  color: var(--white) !important;
  background-color: var(--blue) !important;
}