.beta-test-section {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    margin-left: auto;
    margin-right: auto;
}

.beta-test-section-unstyled {
    padding: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.beta-test-section-rounded {
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.beta-test-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.font-size-20 {
    font-size: 20px;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.label-wrapper.left {
    margin-right: 1.5rem;
}

.label-wrapper.right {
    margin-left: 1.5rem;
}

.label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* Optional: Adjust the spacing between the button pairs */
}

.label-wrapper label {
    margin-bottom: 10px;
    font-weight: bold;
    /* Optional: Adjust the spacing between the label and button */
}

.center-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    color: red;
    font-size: .8rem;
}